////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {useEffect, useReducer} from 'react';
import {Task} from '../dataModel/Task';
import {ExportReportLocationType, FileDestinationNamingType, RevitVersion} from '../clients/Classes';
import {DefaultItem} from '@adsk/alloy-react-dropdown';
import styled from 'styled-components';
import Selector from './Selector';
import {BlueButton, BorderSection, FlexColumn, FlexRow, FlexRowCentered} from '../CommonStyledComponents';
import {reducer} from './reducers/OptionsReducer';
import {OptionsState} from './states/OptionsState';
import {OptionsActions} from '../Enums';
import {ProjectUI} from '../dataModel/ProjectUI';
import ProjectDropdown from './ProjectDropdown';
import FileStructureList from './FileStructureList';
import {DirectoryUI} from '../dataModel/DirectoryUI';
import {ConvertExportLocationType} from '../converters/ConvertExportLocationType';
import {ConvertFileDestinationNamingType} from '../converters/ConvertFileDestinationNamingType';
import {ConvertRevitVersion} from '../converters/ConvertRevitVersion';
import {BIM360ItemBase} from '../dataModel/BIM360ItemBase';
import Theme from "@adsk/alloy-react-theme";
import Checkbox, {CheckboxState} from "@adsk/alloy-react-checkbox";
import {InfoPopover, OverflowTooltip} from "@adsk/alloy-react-tooltip";
import ProgressRing from "@adsk/alloy-react-progress-ring";
import {MoreHorizontalIcon} from "@adsk/alloy-react-icon";
import Modal from '@adsk/alloy-react-modal';
import Button from '@adsk/alloy-react-button';

const LabelWrapper = styled.label`
  display: flex;
  align-items: center;
  margin-top: 1em;
`;

const LabelWrapperIndent = styled(LabelWrapper)`
  margin-left: 2em;
`;

const CheckBoxStyled = styled(Checkbox)`
  margin-right: 7px;
`;

const SettingsOptions = (
  {
    task,
    projects,
    loadingProjects,
    loadingExportLocation,
    exportLocation,
    onLocationChange,
    onPotentialSaveAbilityChange,
  }: {
    task: Task | undefined,
    projects: ProjectUI[],
    loadingProjects: boolean,
    loadingExportLocation: boolean,
    exportLocation: string | undefined,
    onLocationChange?: (location: DirectoryUI) => void,
    onPotentialSaveAbilityChange?: () => void
  }) => {
  const [state, dispatch] = useReducer(reducer, new OptionsState(task));

  useEffect(() => {
    if (projects == null || projects.length === 0) {
      return;
    }
    const project = projects.find(p => p.Id === task?.ExportProjectId) ?? projects[0];

    dispatch({type: OptionsActions.selectedProject, payload: project});
  }, [projects, task?.ExportProjectId]);

  const optionsRevitVersion: DefaultItem[] = [
    {
      value: RevitVersion.Auto,
      label: ConvertRevitVersion.Convert(RevitVersion.Auto)
    },
    {
      value: RevitVersion.V2025,
      label: ConvertRevitVersion.Convert(RevitVersion.V2025)
    },
    {
      value: RevitVersion.V2024,
      label: ConvertRevitVersion.Convert(RevitVersion.V2024)
    },
    {
      value: RevitVersion.V2023,
      label: ConvertRevitVersion.Convert(RevitVersion.V2023)
    },
    {
      value: RevitVersion.V2022,
      label: ConvertRevitVersion.Convert(RevitVersion.V2022)
    },
    {
      value: RevitVersion.V2021,
      label: ConvertRevitVersion.Convert(RevitVersion.V2021)
    },
    {
      value: RevitVersion.V2020,
      label: ConvertRevitVersion.Convert(RevitVersion.V2020)
    }
  ];

  const optionsLocation: DefaultItem[] = [
    {
      value: ExportReportLocationType.ModelDirectory,
      label: ConvertExportLocationType.Convert(ExportReportLocationType.ModelDirectory)
    },
    {
      value: ExportReportLocationType.ModelSubdirectory,
      label: ConvertExportLocationType.Convert(ExportReportLocationType.ModelSubdirectory)
    },
    {
      value: ExportReportLocationType.OtherDirectory,
      label: ConvertExportLocationType.Convert(ExportReportLocationType.OtherDirectory)
    },
  ];

  const optionsNaming: DefaultItem[] = [
    {
      value: FileDestinationNamingType.Overwrite,
      label: ConvertFileDestinationNamingType.Convert(FileDestinationNamingType.Overwrite)
    },
    {
      value: FileDestinationNamingType.AppendTimestamp,
      label: ConvertFileDestinationNamingType.Convert(FileDestinationNamingType.AppendTimestamp)
    },
  ];

  function exportExcelChanged(value: CheckboxState): void {
    if (task == null) {
      return;
    }
    task.ExportExcel = value === true;
    dispatch({type: OptionsActions.exportExcel, payload: value === true});

    if (onPotentialSaveAbilityChange) {
      onPotentialSaveAbilityChange();
    }
  }

  function includeListChanged(value: CheckboxState): void {
    if (task == null) {
      return;
    }
    task.ExportLists = value === true;
    dispatch({type: OptionsActions.includeList, payload: value === true});

    if (onPotentialSaveAbilityChange) {
      onPotentialSaveAbilityChange();
    }
  }

  function combineChanged(value: CheckboxState): void {
    if (task == null) {
      return;
    }
    task.CombineExcel = value === true;
    dispatch({type: OptionsActions.combineReports, payload: value === true});

    if (onPotentialSaveAbilityChange) {
      onPotentialSaveAbilityChange();
    }
  }

  function exportHtmlChanged(value: CheckboxState): void {
    if (task == null) {
      return;
    }
    task.ExportHtml = value === true;
    dispatch({type: OptionsActions.exportHtml, payload: value === true});

    if (onPotentialSaveAbilityChange) {
      onPotentialSaveAbilityChange();
    }
  }

  function namingChanged(value: DefaultItem | null | undefined): void {
    if (task == null) {
      return;
    }
    task.ExportDestinationNaming = value == null
      ? FileDestinationNamingType.None
      : value.value as FileDestinationNamingType;
    dispatch({type: OptionsActions.naming, payload: task.ExportDestinationNaming});

    if (onPotentialSaveAbilityChange) {
      onPotentialSaveAbilityChange();
    }
  }

  function revitVersionChanged(value: DefaultItem | null | undefined): void {
    if (task == null) {
      return;
    }
    task.RevitVersion = value == null
      ? RevitVersion.Auto
      : value.value as RevitVersion;
    dispatch({type: OptionsActions.revitVersion, payload: task.RevitVersion});
    if (onPotentialSaveAbilityChange) {
      onPotentialSaveAbilityChange();
    }
  }

  function locationChanged(value: DefaultItem | null | undefined): void {
    if (task == null) {
      return;
    }
    task.ExportLocationType = value == null
      ? ExportReportLocationType.None
      : value.value as ExportReportLocationType;
    dispatch({type: OptionsActions.location, payload: task.ExportLocationType});

    if (onPotentialSaveAbilityChange) {
      onPotentialSaveAbilityChange();
    }
  }

  function acceptExportLocation(): void {
    dispatch({
      type: OptionsActions.showLocationDialog,
      payload: false
    });

    if (task == null) {
      return;
    }

    if (state.selectedDirectory == null) {
      throw Error('No directory selected');
    }

    if (onLocationChange) {
      onLocationChange(state.selectedDirectory);
    }

    if(onPotentialSaveAbilityChange){
      onPotentialSaveAbilityChange();
    }
  }

  function emailChanged(value: CheckboxState): void {
    if (task == null) {
      return;
    }
    task.EmailOnCompletion = value === true;
    dispatch({type: OptionsActions.email, payload: value === true});

    if (onPotentialSaveAbilityChange) {
      onPotentialSaveAbilityChange();
    }
  }

  function attachChanged(value: CheckboxState): void {
    if (task == null) {
      return;
    }
    task.AttachExportFiles = value === true;
    dispatch({type: OptionsActions.attachExports, payload: value === true});

    if (onPotentialSaveAbilityChange) {
      onPotentialSaveAbilityChange();
    }
  }

  function dialogSelectionChange(currentSelection: { item: BIM360ItemBase, newIsSelected: boolean }[]): void {
    const selectedItems = currentSelection.filter(s => s.newIsSelected);
    if (selectedItems.length > 1) {
      throw Error('Only single selection is supported for destination');
    }
    if (selectedItems.length > 0 && !(selectedItems[0].item instanceof DirectoryUI)) {
      throw Error('Incorrect object type passed, must be a directory.');
    }
    dispatch({
      type: OptionsActions.selectedDirectory,
      payload: selectedItems.length === 0 ? undefined : selectedItems[0].item
    });
  }

  return (
    <div>
      <h2 style={Theme.typography.heading2}>Options</h2>
      <div style={{marginTop: '2em'}}>
        <FlexRowCentered style={{marginTop: '0.5em'}}>
          <div style={{width: '150px'}}>
            <p style={Theme.typography.bodyMedium}>Revit Version</p>
          </div>
          <Selector items={optionsRevitVersion}
                    selected={optionsRevitVersion.find(o => o.value === state.revitVersion)}
                    onSelectionChange={revitVersionChanged}/>
          <InfoPopover
            style={{marginLeft: '1em'}}
            message={'Revit version should generally be left on Auto but specific versions can be used for troubleshooting.'}/>
        </FlexRowCentered>
        <LabelWrapper>
          <CheckBoxStyled checked={state.exportExcel}
                          onChange={exportExcelChanged}/>
          <span style={Theme.typography.labelMedium}>Export the report in Excel XLSX format</span>
        </LabelWrapper>
        <LabelWrapperIndent>
          <CheckBoxStyled checked={state.includeList}
                          disabled={task == null || !task.ExportExcel}
                          onChange={includeListChanged}/>
          <span style={Theme.typography.labelMedium}>Include list elements</span>
        </LabelWrapperIndent>
        <LabelWrapperIndent>
          <CheckBoxStyled checked={state.combineReports}
                          disabled={task == null || !task.ExportExcel || task.Trigger === 'OnPublish'}
                          onChange={combineChanged}/>
          <span style={Theme.typography.labelMedium}>Combine all reports</span>
        </LabelWrapperIndent>
        <LabelWrapper>
          <CheckBoxStyled checked={state.exportHtml}
                          onChange={exportHtmlChanged}/>
          <span style={Theme.typography.labelMedium}>Export the report in HTML format</span>
          <InfoPopover style={{marginLeft: '1em'}}
                       message={<>
                         <p style={{marginTop: 0}}>Due to limitations in Docs, the HTML reports will be attached as a
                           zip file
                           for you to download and unzip from your email.</p>
                         <p style={{marginBottom: 0}}>Please note that some spam filters will quarantine emails with
                           HTML attachments.</p>
                       </>}/>
        </LabelWrapper>
        <FlexRowCentered style={{marginTop: '0.5em'}}>
          <div style={{width: '150px'}}>
            <p style={Theme.typography.bodyMedium}>Export Location</p>
          </div>
          <Selector items={optionsLocation}
                    disabled={!state.exportExcel && !state.exportHtml}
                    selected={optionsLocation.find(o => o.value === state.location)}
                    onSelectionChange={locationChanged}/>
        </FlexRowCentered>
        {
          (state.exportExcel || state.exportHtml) && state.location === ExportReportLocationType.OtherDirectory &&
          <FlexRowCentered style={{marginTop: '0.5em'}}>
            <div style={{width: '150px'}}>
              <div style={{marginLeft: '2em'}}>
                <p style={Theme.typography.bodyMedium}>Location</p>
              </div>
            </div>
            <FlexRowCentered style={{width: '300px'}}>
              {
                loadingExportLocation && <ProgressRing size={'small'}/>
              }
              <BorderSection style={{
                flex: 1,
                background: 'white',
                height: '36px',
                display: 'flex',
                alignItems: 'center',
                padding: '0 .5em'
              }}>
                <OverflowTooltip
                  style={{color: Theme.colors.charcoal500}}>{exportLocation}</OverflowTooltip>
              </BorderSection>
              <Button
                style={{alignSelf: 'center', minWidth: 0}}
                disabled={!state.exportExcel && !state.exportHtml}
                onClick={() => dispatch({
                  type: OptionsActions.showLocationDialog,
                  payload: true
                })}>
                <MoreHorizontalIcon/>
              </Button>
            </FlexRowCentered>
          </FlexRowCentered>
        }
        <FlexRowCentered style={{marginTop: '0.5em'}}>
          <div style={{width: '150px'}}>
            <p style={Theme.typography.bodyMedium}>Export File Naming</p>
          </div>
          <Selector items={optionsNaming}
                    disabled={!state.exportExcel && !state.exportHtml}
                    selected={optionsNaming.find(o => o.value === state.naming)}
                    onSelectionChange={namingChanged}/>
        </FlexRowCentered>
        <LabelWrapper>
          <CheckBoxStyled checked={state.email}
                          onChange={emailChanged}/>
          <span style={Theme.typography.labelMedium}>Email me when each run of this task completes</span>
        </LabelWrapper>
        <LabelWrapperIndent>
          <CheckBoxStyled checked={state.attachExports}
                          disabled={task == null || !task.EmailOnCompletion}
                          onChange={attachChanged}/>
          <span
            style={Theme.typography.labelMedium}>Attach my exported reports (Excel XLSX and/or HTML)</span>
          <InfoPopover style={{marginLeft: '1em'}}
                       message={'Separate files will be generated for each model and attached to the email'}/>
        </LabelWrapperIndent>
      </div>
      <Modal open={state.showLocationDialog}>
        <Modal.Header>Select Save Path</Modal.Header>
        <Modal.Body>
          <FlexColumn>
            <div style={Theme.typography.bodyMedium}>Select a project and folder</div>
            <ProjectDropdown
              projects={projects}
              selectedProject={state.selectedProject}
              loadingProjects={loadingProjects}
              onSelected={p => dispatch({
                type: OptionsActions.selectedProject,
                payload: p
              })}/>
            <FileStructureList
              project={state.selectedProject}
              allowSelection={true}
              allowMultiSelection={false}
              onSelectionChange={dialogSelectionChange}
              foldersOnly={true}
              allowAdd={false}/>
          </FlexColumn>
        </Modal.Body>
        <Modal.Footer>
          <FlexRow style={{justifyContent: 'end'}}>
            <BlueButton onClick={() => acceptExportLocation()} disabled={state.selectedDirectory == null}>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>OK</span>
              </FlexRowCentered>
            </BlueButton>
            <BlueButton style={{marginLeft: '1em'}}
                        onClick={() => dispatch({
                          type: OptionsActions.showLocationDialog,
                          payload: false
                        })}>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>Cancel</span>
              </FlexRowCentered>
            </BlueButton>
          </FlexRow>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SettingsOptions;
